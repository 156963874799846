export function fetchEditProductPageData(slug: string) {
    const { get } = useOFetchCustom(`products/${slug}/edit`)
    const editProductData = useEditProductData()

    async function fetch() {
        return new Promise((resolve, reject) => {
            get(undefined, {
                onSuccess: (response: any) => {
                    editProductData.value = response.data
                    resolve(response)
                },
                onError: (error: any) => {
                    reject(error)
                },
            })
        })
    }

    return { fetch, editProductData }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    const { fetch } = fetchEditProductPageData(to.params.slug as string)

    try {
        await fetch()
    } catch (error: any) {
        return navigateTo("/store")
    }
})
